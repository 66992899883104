function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
      show_language_content(false);
    });
  });
}


$(function() {
/*********************************************************************
  common
*********************************************************************/
  slideout_menu();
  $('.js-sync_slide_box').each(function(){
    var $main_slide=$(this).find('.js-sync_slide_main');
    var $sub_slide=$(this).find('.js-sync_slide_sub');
    var $controller=$(this).find('.js-sync_slide_control');

    $main_slide.on('init',function(event,slick){
      $controller.find('.current').text(slick.currentSlide + 1);
      $controller.find('.total').text(slick.slideCount);
    })
    .slick({
      fade:true,
      infinite: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: $sub_slide,
      arrows:true,
      appendArrows:$controller.find('.controller'),
      prevArrow: '<div class="arrow prev"></div>',
      nextArrow: '<div class="arrow next"></div>',
      responsive:[
        {
            breakpoint: 992,
            settings:{
              arrows:false,
              dots:true,
              appendDots:$controller.find('.controller'),
            }
        },
      ]
    })
    .on('beforeChange',function(event, slick, currentSlide, nextSlide){
      $controller.find('.current').text(nextSlide + 1);
    });

    $sub_slide.slick({
      fade:true,
      infinite: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: $main_slide,
      arrows:false,
      dots:false,
    });
  });
  
  $('.js-editor').each(function(){
    $(this).find('table').each(function(){
      $(this).wrap('<div class="c-rwdsheet"><div class="c-rwdsheet__inner"></div></div>');
    });
  });
/*********************************************************************
  index
*********************************************************************/

});
$(window).on('load',function(){
  $('.js-key_anime').addClass('is-anime');
});
$(window).on('scroll',function(){
});
$(window).on('resize',function(){
  winWidth = $(window).width();
});
